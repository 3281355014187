import React from 'react'
import ReactDOM from 'react-dom/client'
import { ConfigProvider, theme } from 'antd'
import App from './App.tsx'
import zhCN from 'antd/locale/zh_CN'
import { Provider } from 'mobx-react'
import store from '@/store'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        locale={zhCN}
        theme={{
          token: {
            // Seed Token，影响范围大
            colorPrimary: '#00b96b',
            borderRadius: 4,
    
            // 派生变量，影响范围小
            colorBgContainer: '#f6ffed',
          },
          algorithm: theme.defaultAlgorithm,
        }}>
          <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
)
