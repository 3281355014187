import styles from './index.module.scss'
import Menu from '@/components/Menu'
function Top(props: any) {
  return <div className={styles.comp}>
    <div className={styles.topbar}>
      <Menu />
      {props.children}
    </div>
  </div>
}

export default Top
