import { useEffect } from 'react'
import styles from './index.module.scss'
import illstrator from '@/assets/illstrator.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd'
import routes from '@/routes'
import { login } from '@/apis/member'
import Logo from '@/components/Login/Logo'
import Back from '@/components/Login/Back'
import store from '@/store'

function Login() {
  const location = useLocation()
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  useEffect(() => {
    const currRoute = routes.filter(i => i.path === location.pathname)[0]
    document.title = currRoute.meta.title
  })
  
  const [form] = Form.useForm();

  const onFinish = (values: FieldType) => {
    
    login(values).then((res: any) => {
      if(res.code === 0) {
        store.setToken(res.data)
        navigate('/')
      } else {
        messageApi.open({
          type: 'error',
          content: res.message
        })
      }
    })
    
  }

  return <div className={styles.contain}>
    {contextHolder}
    <div style={{
      position: 'absolute',
      top: 20,
      left: 20,
      zIndex: 2,
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center'
    }}>
      <Logo scale={0.5} />
      <h1 className={styles.title}>积微系统</h1>
    </div>
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
    }}>
      <Back />
    </div>
    <div className={styles.app}>
      <div className={styles.illustration}>
        <img src={illstrator} className={styles.ill} />
      </div>
      <div className={styles.loginBox}>
        <div className={styles.loginContain}>
          <h2>积微系统-登录</h2>
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            size='large'
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
          >
            <Form.Item<FieldType>
              label="账号："
              name="account"
              rules={[{ required: true, message: '请输入账号！' }]}>
              <Input
                prefix={
                  <svg className='icon' aria-hidden='true' width="16" height="16" style={{
                    position: 'relative',
                    userSelect: 'none',
                    pointerEvents: 'none',
                    color: '#fff',
                  }}>
                    <use xlinkHref="#icon-username"></use>
                  </svg>
                }
                placeholder="请输入账号"
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="密码："
              name="password"
              rules={[{ required: true, message: '请输入密码！' }]}>
              <Input.Password
                prefix={
                  <svg className='icon' aria-hidden='true' width="16" height="16" style={{
                    position: 'relative',
                    userSelect: 'none',
                    pointerEvents: 'none',
                    color: '#fff',
                  }}>
                    <use xlinkHref="#icon-password"></use>
                  </svg>
                }
                placeholder="请输入密码"
              />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button type="primary" htmlType="submit" block>
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  </div>
}

export default Login
