import { FC, useState, useEffect, useRef } from 'react'
import styles from './index.module.scss'
import { observer } from 'mobx-react'
import { Form, Input, Button, Select } from 'antd'
import Editor from '@/components/Editor'
import store from '@/store'
import { rolesListAll } from '@/apis/role'
import { pagesListAll } from '@/apis/page'
import { useSearchParams } from 'react-router-dom'

const save = (form: any) => {
  console.log(form.getFieldsValue())

  return false
}

const Active: FC = () => {
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [roles, setRoles] = useState([])
  const [pages, setPages] = useState([])
  const [searchParams] = useSearchParams()
  useEffect(() => {
    if(formRef.current) {
      form.setFieldsValue({
        id: store.nostorage.attrs.id,
        name: '活动名称一',
        pageId: ''
      })
    }

    rolesListAll({
      application_id: searchParams.get('application_id')
    }).then(({ data }) => {
      setRoles(data.map((item: ANY_JSON) => ({
        label: item.role_name,
        value: item.id
      })))
    })

    pagesListAll({
      application_id: searchParams.get('application_id')
    }).then(({ data }) => {
      setPages(data.map((item: ANY_JSON) => ({
        label: item.role_name,
        value: item.id
      })))
    })
  }, [])

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  
  return <div className={styles.active}>
    <Form
      form={form}
      ref={formRef}
      name="active"
      layout="vertical"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={() => save(form)}
    >
      <Form.Item
        name="id"
        label="活动ID："
      >
        <span style={{ fontSize: '12px' }}>{store.nostorage.attrs.id}</span>
      </Form.Item>
      <Form.Item
        name="name"
        label="活动名称："
        rules={[{ required: true, message: '请输入活动名称！' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="role"
        label="绑定角色："
        rules={[{ required: true, message: '请绑定当前节点处理的角色！' }]}
      >
        <Select
          onChange={handleChange}
          options={roles}
        />
      </Form.Item>
      <Form.Item
        name="pages_key"
        label="绑定表单："
        rules={[{ required: true, message: '请绑定活动表单！' }]}
      >
        <Select
          onChange={handleChange}
          options={pages}
        />
      </Form.Item>
  
      <Form.Item wrapperCol={{ span: 24 }}>
        <Button type="primary" htmlType="submit" block>
          保存
        </Button>
      </Form.Item>
    </Form>
  </div>
}

const Condition: FC = () => {
  const [form] = Form.useForm()
  const formRef = useRef(null)
  useEffect(() => {
    if(formRef.current) {
      form.setFieldsValue({
        id: store.nostorage.attrs.id,
        name: '条件名称一',
        code: ''
      })
    }
  }, [])
  
  return <div className={styles.condition}>
    <Form
      form={form}
      ref={formRef}
      name="condition"
      layout="vertical"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={() => save(form)}
    >
      <Form.Item
        name="id"
        label="条件ID："
      >
        <span style={{ fontSize: '12px' }}>{store.nostorage.attrs.id}</span>
      </Form.Item>
      <Form.Item
        name="name"
        label="条件名称："
        rules={[{ required: true, message: '请输入条件名称！' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        name="code"
        label="条件代码："
        rules={[{ required: true, message: '请输入条件代码！' }]}
      >
        <Editor
          value={form.getFieldValue('code')}
          onChange={() => {
            form.setFieldValue('code', form.getFieldValue('code'))
          }}
        />
      </Form.Item>
  
      <Form.Item wrapperCol={{ span: 24 }}>
        <Button type="primary" htmlType="submit" block>
          保存
        </Button>
      </Form.Item>
    </Form>
  </div>
}

const Task: FC = () => {
  const [form] = Form.useForm()
  const formRef = useRef(null)
  useEffect(() => {
    if(formRef.current) {
      form.setFieldsValue({
        id: store.nostorage.attrs.id,
        name: '任务名称一',
        code: ''
      })
    }
  }, [])

  return <div className={styles.task}>
    <Form
      form={form}
      ref={formRef}
      name="task"
      layout="vertical"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={() => save(form)}
    >
      <Form.Item
        name="id"
        label="任务ID："
      >
        <span style={{ fontSize: '12px' }}>{store.nostorage.attrs.id}</span>
      </Form.Item>
      <Form.Item
        name="name"
        label="任务名称："
        rules={[{ required: true, message: '请输入任务名称！' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{span: 24}}
        wrapperCol={{span: 24}}
        name="code"
        label="任务代码："
        rules={[{ required: true, message: '请输入任务代码！' }]}
      >
        <Editor
          value={form.getFieldValue('code')}
          onChange={() => {
            form.setFieldValue('code', form.getFieldValue('code'))
          }}
        />
      </Form.Item>
  
      <Form.Item wrapperCol={{ span: 24 }}>
        <Button type="primary" htmlType="submit" block>
          保存
        </Button>
      </Form.Item>
    </Form>
  </div>
}

type COMP = {id: string, component: FC}

function Attributes() {
  const [comps] = useState<COMP[]>([
    {
      id: 'active',
      component: observer(Active),
    },
    {
      id: 'condition',
      component: observer(Condition),
    },
    {
      id: 'task',
      component: observer(Task),
    }
  ])
  
  return <div className={styles.comp}>
    {
      comps.filter((item) => {
        return item.id === store.nostorage.attrs?.shape
      }).map((item) => {
        return <div key={item.id}>
          <item.component key={item.id} />
        </div>
      })
    }
  </div>
}

export default observer(Attributes)
