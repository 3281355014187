import { SetStateAction, useState } from 'react'
import bus from '@/utils/bus'
import { getId } from '@/utils'
import { Input, Button } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styles from './index.module.scss'

function Topbar() {
  const [ processId ] = useState(getId())
  const [ processName, setProcessName ] = useState('流程一')

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const getValue = (val: { target: { value: SetStateAction<string> } }) => {
    setProcessName(val.target.value)
  }

  const saveChange = () => {
    console.log('TODO 保存：', processName)
  }

  return <div className={styles.comp}>
    <ul className={styles.barUl}>
      <li className={styles.barProcessInfo}>
        <div className={styles.barProcessId}>
          {searchParams.get('application_name')}
        </div>
        <div className={styles.barProcessId}>
          <div className={styles.barProcessLabel}>
            流程ID：
          </div>
          <div className={styles.barProcessIdVal}>{processId}</div>
        </div>
        <div className={styles.barProcessName}>
          <div className={styles.barProcessLabel}>
            流程名称：
          </div>
          <div>
            <Input
              value={processName}
              placeholder="请输入流程名称"
              onChange={getValue}
              onBlur={saveChange}
            />
          </div>
        </div>
      </li>
      <li>
        {/* <Button type="primary" danger ghost onClick={() => bus.emit('x6.export')} style={{marginRight:10}}>
        <svg className='icon' aria-hidden='true' width="16" height="16" style={{
          position: 'relative',
          top: '3px',
          userSelect: 'none',
          pointerEvents: 'none',
          marginRight: '6px'
        }}>
          <use xlinkHref="#icon-import"></use>
        </svg>
          导入
        </Button>
        <Button type="primary" ghost onClick={() => bus.emit('x6.export')} style={{marginRight:10}}>
          <svg className='icon' aria-hidden='true' width="16" height="16" style={{
            position: 'relative',
            top: '3px',
            userSelect: 'none',
            pointerEvents: 'none',
            marginRight: '6px'
          }}>
            <use xlinkHref="#icon-export"></use>
          </svg>
          导出
        </Button> */}
        <Button type="primary" ghost onClick={() => navigate(-1)} style={{marginRight:10}} danger>
          <svg className='icon' aria-hidden='true' width="16" height="16" style={{
            position: 'relative',
            top: '3px',
            userSelect: 'none',
            pointerEvents: 'none',
            marginRight: '6px'
          }}>
            <use xlinkHref="#icon-back"></use>
          </svg>
          返回
        </Button>
        <Button type="primary" ghost onClick={() => bus.emit('x6.export')}>
          <svg className='icon' aria-hidden='true' width="16" height="16" style={{
            position: 'relative',
            top: '3px',
            userSelect: 'none',
            pointerEvents: 'none',
            marginRight: '6px',
            color: '#fff',
          }}>
            <use xlinkHref="#icon-save"></use>
          </svg>
          保存
        </Button>
      </li>
    </ul>
  </div>
}

export default Topbar
