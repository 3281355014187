import { md5 } from 'js-md5'

/**
 * 获取ID
 * @returns 字符串ID
 */
export const getId = (): string => md5(`${Date.now()}-${Math.random()}`).replace(/\B(?=(\w{8})+(?!\w))/g, '-')

/**
 * 加密
 * @param str 加密字符串
 * @returns 加密数组
 */
export const ascEncode = (str: string): number[] => {
  const arr: number[] = []
  if(!str) {
    return arr
  }
  for (let i = 0;i < str.length; i++) {
    arr.push((str.charCodeAt(i) - 2) * 5)
  }

  return arr
}

/**
 * 解密
 * @param arr 加密数组
 * @returns 解密字符串
 */
export const ascDecode = (arr: number[]): string => {
  let str: string = ''
  if(arr.length <= 0) {
    return str
  }
  for (let i = 0;i < arr.length; i++) {
    str += String.fromCharCode(((Number(arr[i]) / 5 ) + 2))
  }

  return str
}

/**
 * 文件对象转base64
 * @param file 文件对象
 * @returns {Promise<String>}
 */
export const fileToBase64Async = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      resolve(e.target?.result)
    }
  })
}