import axios from '@/utils/axios'

export const pagesList = (data: ANY_JSON) => {
  return axios.get('/pages/list', {
    params: data,
    headers: {
      sign: true
    }
  })
}

export const pagesListAll = (data: ANY_JSON) => {
  return axios.get('/pages/list-all', {
    params: data,
    headers: {
      sign: true
    }
  })
}

export const pagesAdd = (data: ANY_JSON) => {
  return axios.post('/pages/add', data, {
    headers: {
      sign: true
    }
  })
}