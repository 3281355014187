import axios from '@/utils/axios'

export const commonUpload = (file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post('/common/upload', formData, {
    headers: {
      'content-type': 'multipart/form-data',
      sign: true
    }
  })
}