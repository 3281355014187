import React from 'react'
import styles from './index.module.scss'
import Process from '@/components/Process'


class Root extends React.Component<ANY_JSON> {
  constructor(props: ANY_JSON) {
    super(props)
  }
  
  render() {
    return <div className={styles.app}>
      <Process />
    </div>
  }
}

export default Root
