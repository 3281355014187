 
// observable:转换为可观察对象
// autorun:监听
//runInAction:处理异步
import {observable, configure, action, makeAutoObservable} from 'mobx'
import { makePersistable, isHydrated } from 'mobx-persist-store'
import Sm4 from '@/utils/sm4'
const sm4 = new Sm4()
// import axios from 'axios'
// 严格模式， 必须写action,
// 如果是never，可以不写action,
// 最好设置always, 防止任意地方修改值， 降低不确定性。
configure({enforceActions: "always"})
 
class Store {

    @observable state: ANY_JSON = {
      x6InitNodes: [
        {
          id: 'start',
          name: '开始',
          icon: 'icon-start',
          width: 60,
          height: 60,
          bgColor: '#aef6a9',
          fnColor: '#4eb547',
          ports: {
            groups: {
              bottom: {
                zIndex: 999,
                attrs: {
                  circle: {
                    magnet: true,
                    fill: 'transparent',
                    stroke: '#8f8f8f',
                    r: 4,
                  },
                },
                position: {
                  name: 'absolute',
                  args: { x: 30, y: 58 },
                },
              },
            },
            items: [
              {
                id: 'out',
                group: 'bottom',
              },
            ],
          },
          component: undefined
        },
        {
          id: 'active',
          name: '活动',
          icon: 'icon-active',
          width: 100,
          height: 42,
          bgColor: '#fff',
          fnColor: '#333',
          ports: {
            groups: {
              top: {
                zIndex: 999,
                attrs: {
                  circle: {
                    magnet: true,
                    fill: 'transparent',
                    stroke: '#8f8f8f',
                    r: 4,
                  },
                },
                position: {
                  name: 'absolute',
                  args: { x: 50, y: 4 },
                },
              },
              bottom: {
                zIndex: 999,
                attrs: {
                  circle: {
                    magnet: true,
                    fill: 'transparent',
                    stroke: '#8f8f8f',
                    r: 4,
                  },
                },
                position: {
                  name: 'absolute',
                  args: { x: 50, y: 40 },
                },
              },
            },
            items: [
              {
                id: 'in',
                group: 'top',
              },
              {
                id: 'out',
                group: 'bottom',
              },
            ],
          },
          component: undefined
        },
        {
          id: 'condition',
          name: '条件',
          icon: 'icon-condition',
          width: 100,
          height: 42,
          bgColor: '#fff',
          fnColor: '#333',
          ports: {
            groups: {
              top: {
                zIndex: 999,
                attrs: {
                  circle: {
                    magnet: true,
                    fill: 'transparent',
                    stroke: '#8f8f8f',
                    r: 4,
                  },
                },
                position: {
                  name: 'absolute',
                  args: { x: 50, y: 4 },
                },
              },
              bottom: {
                zIndex: 999,
                attrs: {
                  circle: {
                    magnet: true,
                    fill: 'transparent',
                    stroke: '#8f8f8f',
                    r: 4,
                  },
                },
                position: {
                  name: 'absolute',
                  args: { x: 50, y: 40 },
                },
              },
            },
            items: [
              {
                id: 'in',
                group: 'top',
              },
              {
                id: 'out',
                group: 'bottom',
              },
            ],
          },
          component: undefined
        },
        {
          id: 'task',
          name: '自动任务',
          icon: 'icon-task',
          width: 150,
          height: 42,
          bgColor: '#fff',
          fnColor: '#333',
          ports: {
            groups: {
              top: {
                zIndex: 999,
                attrs: {
                  circle: {
                    magnet: true,
                    fill: 'transparent',
                    stroke: '#8f8f8f',
                    r: 4,
                  },
                },
                position: {
                  name: 'absolute',
                  args: { x: 75, y: 4 },
                },
              },
              bottom: {
                zIndex: 999,
                attrs: {
                  circle: {
                    magnet: true,
                    fill: 'transparent',
                    stroke: '#8f8f8f',
                    r: 4,
                  },
                },
                position: {
                  name: 'absolute',
                  args: { x: 75, y: 40 },
                },
              },
            },
            items: [
              {
                id: 'in',
                group: 'top',
              },
              {
                id: 'out',
                group: 'bottom',
              },
            ],
          },
          component: undefined
        },
        {
          id: 'split',
          name: '拆分',
          icon: 'icon-split',
          width: 60,
          height: 60,
          bgColor: '#fff',
          fnColor: '#333',
          ports: {
            groups: {
              top: {
                zIndex: 999,
                attrs: {
                  circle: {
                    magnet: true,
                    fill: 'transparent',
                    stroke: '#8f8f8f',
                    r: 4,
                  },
                },
                position: {
                  name: 'absolute',
                  args: { x: 30, y: 4 },
                },
              },
              bottom: {
                zIndex: 999,
                attrs: {
                  circle: {
                    magnet: true,
                    fill: 'transparent',
                    stroke: '#8f8f8f',
                    r: 4,
                  },
                },
                position: {
                  name: 'absolute',
                  args: { x: 30, y: 58 },
                },
              },
            },
            items: [
              {
                id: 'in',
                group: 'top',
              },
              {
                id: 'out',
                group: 'bottom',
              },
            ],
          },
          component: undefined
        },
        {
          id: 'merge',
          name: '合并',
          icon: 'icon-merge',
          width: 60,
          height: 60,
          bgColor: '#fff',
          fnColor: '#333',
          ports: {
            groups: {
              top: {
                zIndex: 999,
                attrs: {
                  circle: {
                    magnet: true,
                    fill: 'transparent',
                    stroke: '#8f8f8f',
                    r: 4,
                  },
                },
                position: {
                  name: 'absolute',
                  args: { x: 30, y: 4 },
                },
              },
              bottom: {
                zIndex: 999,
                attrs: {
                  circle: {
                    magnet: true,
                    fill: 'transparent',
                    stroke: '#8f8f8f',
                    r: 4,
                  },
                },
                position: {
                  name: 'absolute',
                  args: { x: 30, y: 58 },
                },
              },
            },
            items: [
              {
                id: 'in',
                group: 'top',
              },
              {
                id: 'out',
                group: 'bottom',
              },
            ],
          },
          component: undefined
        },
        {
          id: 'end',
          name: '结束',
          icon: 'icon-end',
          width: 60,
          height: 60,
          bgColor: '#ffdccb',
          fnColor: '#ca6331',
          ports: {
            groups: {
              top: {
                zIndex: 999,
                attrs: {
                  circle: {
                    magnet: true,
                    fill: 'transparent',
                    stroke: '#8f8f8f',
                    r: 4,
                  },
                },
                position: {
                  name: 'absolute',
                  args: { x: 30, y: 4 },
                },
              },
            },
            items: [
              {
                id: 'in',
                group: 'top',
              }
            ],
          },
          component: undefined
        },
      ],
      token: ''
    }
    @observable nostorage: ANY_JSON = {
      attrs: {
        id: '',
        shape: ''
      }
    }

    constructor() {
      makeAutoObservable(this, {}, { autoBind: true })
      makePersistable(this, {
        name: 'store@@datas',
        storage: window.sessionStorage,
        properties: ['state']
      })
    }

    get isHydrated() {
      return isHydrated(this)
    }

    @action
    setAttrs(attrs: any = {
      id: '',
      shape: ''
    }) {
      this.nostorage.attrs = attrs
    }

    @action
    setToken(token: string = '') {
      this.state.token = token
    }

    @action
    get userinfo() {
      return sm4.decrypt(this.state.token)
    }
 
    // 异步
    // @action
    // getCinemaList(){
    //     axios({
    //         url:"https://m.maizuo.com/gateway?cityId=510100&ticketFlag=1&k=7406159",
    //         method:"get",
    //         headers:{
    //             'X-Client-Info': '{"a":"3000","ch":"1002","v":"5.0.4","e":"16395416565231270166529","bc":"510100"}',
                
    //             'X-Host': 'mall.film-ticket.cinema.list'
      
    //         }
    //     }).then(res => {
    //         // runInAction 解决异步问题
    //         runInAction(() => {
              
    //             this.state.cinemas = res.data.data.cinemas
    //         })
    //     })
    // }

  //   @action getFilmsList() {
  //     axios({
  //         url: "https://m.maizuo.com/gateway?cityId=510100&pageNum=1&pageSize=20&type=1&k=5994245",
  //         method: "get",
  //         headers: {
  //           'X-Client-Info': '{"a":"3000","ch":"1002","v":"5.2.1","e":"17049421533946499419406337"}',
  //           'X-Host': 'mall.film-ticket.film.list'
  //         }
  //     }).then(res => {
  //         // runInAction 解决异步问题
  //         runInAction(() => {
  //             this.state.films = res.data.data.films
  //         })
  //     })
  // }
  
}
export default new Store()
