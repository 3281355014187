import styles from './index.module.scss'
import { Button, Modal, Form, Input, message, Select } from 'antd'
import Top from '@/components/Top'
import ListCards from '@/components/ListCards'
import { useEffect, useState } from 'react'
import { membersAdd, membersEdit, membersList, membersGet, membersDelete } from '@/apis/member'
import { rolesListAll } from '@/apis/role'

type ADD_PROPS = {
  title: string;
  visible: boolean;
  onOK: (item: ANY_JSON) => void;
  onCancel: () => void;
  init?: ANY_JSON;
}

type FieldType = {
  username: string;
  account: string;
  phone: string;
  role_id: number[];
  password: string;
  confirmPassword: string;
}

const Add = (props: ADD_PROPS) => {
  const [form] = Form.useForm()
  const [opRoles, setOpRoles] = useState<ANY_JSON[]>([])
  
  useEffect(() => {
    if (props.visible) {
      props.init && form.setFieldsValue(props.init)
      setOpRoles([])
      rolesListAll().then((res: any) => {
        if(res.code === 0) {
          const options = res.data.map((item: ANY_JSON) => {
            return {
              value: item.id,
              label: item.role_name
            }
          })
          setOpRoles(options)
        }
      })

    }
    
  }, [
    props.visible
  ])
  
  const handleOk = () => {
    form.validateFields().then((res) => {
      delete res.confirmPassword
      props.onOK && props.onOK(res)
    })
  }

  const handleCancel = () => {
    form.resetFields()
    props.onCancel && props.onCancel()
  }

  const onChangeRole = () =>  {}

  return (
    <Modal
      title={props.title}
      open={props.visible}
      maskClosable={false}
      onOk={handleOk}
      onCancel={handleCancel}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label="姓名："
            name="username"
            rules={[{ required: true, message: '请输入姓名!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label="账号："
            name="account"
            rules={[{ required: true, message: '请输入账号!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="手机号："
            name="phone"
            rules={[{ required: true, message: '请输入手机号!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="绑定角色："
            name="role_id"
            rules={[{ required: true, message: '请绑定角色!' }]}
          >
            <Select
              mode="multiple"
              onChange={onChangeRole}
              options={opRoles}
            />
          </Form.Item>
          <Form.Item<FieldType>
            label="密码"
            name="password"
            rules={props.init && props.init.id ? [] : [{ required: true, message: '请输入密码!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item<FieldType>
            label="确认密码"
            name="confirmPassword"
            rules={props.init && props.init.id ? [] : [{ required: true, message: '请输入确认密码!' }, { validator(_, value, callback) {
              if(value !== form.getFieldValue('password')) {
                callback('两次输入密码不一致')
              } else {
                callback()
              }
            },}]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
    </Modal>
  );
}

function List() {
  const [lists, setLists] = useState<ANY_JSON[]>([])
  const [init, setInit] = useState<ANY_JSON>({})
  const [title, setTitle] = useState<string>('添加人员')
  const [visible, setVisible] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage()
  const [modal, contextHolderModal] = Modal.useModal()

  const confirm = (item: ANY_JSON) => {
    modal.confirm({
      title: '删除',
      icon: <svg className='icon' aria-hidden='true' width="24" height="24" style={{
        userSelect: 'none',
        pointerEvents: 'none',
        marginRight: '10px',
        color: '#fff',
      }}>
        <use xlinkHref="#icon-tip"></use>
      </svg>,
      content: '您确定删除此人员吗？',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        membersDelete({ id: item.id }).then((res: ANY_JSON) => {
          if(res.code === 0) {
            getLists()
            messageApi.success('删除成功')
          } else {
            messageApi.error(res.message)
          }
          
        }).catch(() => {
          messageApi.error('系统错误，删除失败')
        })
      }
    })
  }


  const [page, setPage] = useState<{
    currPage: number;
    total: number;
  }>({
    currPage: 1,
    total: 0
  })
  const getLists = async (params: ANY_JSON = {}) => {
    const res = await membersList(params)
    setPage({
      currPage: res.data.currPage,
      total: res.data.total
    })
    setLists(res.data.lists.map((i: ANY_JSON)=> {
      i.icon = 'icon-member'
      return i
    }))
  }
  

  useEffect(() => {
    getLists()
  },[])

  const onAdd = () => {
    setTitle('添加人员信息')
    setInit({
      'id': void 0,
      'username': '',
      'account': '',
      'phone': '',
      'password': '',
      'confirmPassword': '',
      'role_id': [],
    })
    setVisible(true)
  }
  const onSearch = (params: ANY_JSON) => {
    getLists(params)
  }
  const onEdit = (item: ANY_JSON) => {
    setTitle('修改人员信息')
    membersGet({id: item.id}).then((res: ANY_JSON) => {
      setInit({
        ...res.data,
        role_id: res.data.roles.map((i: ANY_JSON) => i.id)
      })
      setVisible(true)
    })
    
  }
  const onDelete = (item: ANY_JSON) => {
    confirm(item)
  }

  const onOK = (item: ANY_JSON) => {
    if (init && init.id) {

      const params: ANY_JSON = {
        id: init.id
      }
      
      Object.keys(item).forEach((key) => {
        if (item[key]) {
          params[key] = item[key]
        }
      })

      membersEdit(params).then((res: ANY_JSON) => {
        if(res.code === 0) {
          messageApi.success(res.message)
          setVisible(false)
          getLists()
        } else {
          messageApi.error(res.message)
        }
      }).catch(() => {
        messageApi.error('系统错误，请稍后重试')
      })
    } else {
      membersAdd({
        ...item
      }).then((res: ANY_JSON) => {
        if(res.code === 0) {
          messageApi.success(res.message)
          setVisible(false)
          getLists()
        } else {
          messageApi.error(res.message)
        }
      }).catch(() => {
        messageApi.error('系统错误，请稍后重试')
      })
    }
  }
  const onCancel = () => {
    setVisible(false)
  }

  return <div className={styles.app}>
    {contextHolder}
    {contextHolderModal}
    <Top>
      <div>
        <Button
          type='primary'
          ghost
          onClick={onAdd}>
          <svg className='icon' aria-hidden='true' width="16" height="16" style={{
            position: 'relative',
            top: '3px',
            userSelect: 'none',
            pointerEvents: 'none',
            marginRight: '6px',
            color: '#fff',
          }}>
            <use xlinkHref="#icon-add-user"></use>
          </svg>
          新增成员
        </Button>
      </div>
    </Top>
    <ListCards
      onEdit={onEdit}
      onDelete={onDelete}
      onSearch={onSearch}
      page={page}
      list={lists}>
        {({ username, account, created_time }) => {
          return <ul style={{color: '#666', fontSize: '14px'}}>
            <li style={{marginBottom: '6px', display: 'flex'}}>
              <div style={{ width: '42px' }}>姓名：</div>
              <div>{username}</div>
            </li>
            <li style={{marginBottom: '6px', display: 'flex'}}>
              <div>账号：</div>
              <div>{account}</div>
            </li>
            <li style={{
                color: '#999',
                fontSize: '13px',
                textAlign: 'right'
              }}>
              {created_time}
            </li>
          </ul>
        }}
    </ListCards>
    <Add
      title={title}
      visible={visible}
      init={init}
      onOK={onOK}
      onCancel={onCancel}
    />
  </div>
}

export default List
