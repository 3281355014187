import { useLocation, useNavigate, RouteObject } from 'react-router-dom'
import { Button, Modal } from 'antd'
import styles from './index.module.scss'
import routes from '@/routes'
import store from '@/store'
import Logo from '@/components/Login/Logo'

function Menu() {
  const location = useLocation()
  const navigate = useNavigate()

  const currMenu = routes.filter((i) => i.path === location.pathname)[0]
  document.title = `${ currMenu?.meta?.title }-${ import.meta.env.VITE_APP_NAME }` || '首页'

  const goto = (item: RouteObject) => {
    navigate(item!.path as string)
  }

  const logout = () => {
    store.setAttrs()
    store.setToken()
    navigate('/login')
  }

  return <div className={styles.comp}>
    <div className={styles.menuIcon}>
      <div>
        <svg className='icon' aria-hidden='true' width="30" height="30" style={{position: 'relative', top: '4px'}}>
          <use xlinkHref="#icon-menu"></use>
        </svg>
      </div>
      <div>
        <span>菜单</span>
        <span>-</span>
        <span>{currMenu?.meta?.title}</span>
      </div>
    </div>
    <div className={styles.menusBox}>
      <ul className={styles.menuList}>
        {
          routes.filter(i => i.meta?.isMenu).sort((a, b) => ((b.meta?.sort as number) - (a.meta?.sort as number))).map((item) => {
            return (
              <li className={`${styles.menuItem} ${item.pid !== '' ? (currMenu.pid.indexOf(item.pid) >= 0 ? styles.active : '') : (currMenu.pid === '' ? styles.active : '')}`} key={item.path} onClick={() => goto(item)}>
                <svg className='icon' aria-hidden='true' width="24" height="24" style={{marginRight: '10px', position: 'relative', top: '4px'}}>
                  <use xlinkHref={`#${item.meta.icon}`}></use>
                </svg>
                <span>{item.meta?.title}</span>
              </li>
            )
          })
        }
      </ul>
      <div className={styles.logout}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{marginRight: '10px', marginTop: '8px'}}>
            <Logo scale={0.25} />
          </div>
          <div>积微系统</div>
        </div>
      <Button
        onClick={() => {
          Modal.confirm({
            title: '退出登录',
            content: '您确定要退出登录当前系统吗？',
            onOk() {
              logout()
            },
            footer: (_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn  />
              </>
            ),
          })
        }}
        icon={
          <svg className='icon' aria-hidden='true' width="20" height="20" style={{ position: 'relative', top: '5px' }}>
            <use xlinkHref='#icon-logout'></use>
          </svg>
        }
        size='large'
        danger>
        ({store.userinfo.username}) 退出
      </Button>
        
      </div>
    </div>
    
  </div>
}

export default Menu
