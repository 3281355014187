import styles from './index.module.scss'
import { Button, Modal, Form, Input, message } from 'antd'
import Top from '@/components/Top'
import ListCards from '@/components/ListCards'
import { useEffect, useState } from 'react'
import { rolesAdd, rolesEdit, rolesList, rolesGet, rolesDelete } from '@/apis/role'

type ADD_PROPS = {
  title: string;
  visible: boolean;
  onOK: (item: ANY_JSON) => void;
  onCancel: () => void;
  init?: ANY_JSON;
}

type FieldType = {
  role_name: string;
}

const Add = (props: ADD_PROPS) => {
  const [form] = Form.useForm()
  
  useEffect(() => {
    if (props.visible) {
      props.init && form.setFieldsValue(props.init)
    }
    
  }, [
    props.visible
  ])
  
  const handleOk = () => {
    form.validateFields().then((res) => {
      delete res.confirmPassword
      props.onOK && props.onOK(res)
    })
  }

  const handleCancel = () => {
    form.resetFields()
    props.onCancel && props.onCancel()
  }

  return (
    <Modal
      title={props.title}
      open={props.visible}
      maskClosable={false}
      onOk={handleOk}
      onCancel={handleCancel}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label="角色名称："
            name="role_name"
            rules={[{ required: true, message: '请输入角色名称!' }]}
          >
            <Input />
          </Form.Item>
          
        </Form>
    </Modal>
  );
}

function List() {
  const [lists, setLists] = useState<ANY_JSON[]>([])
  const [init, setInit] = useState<ANY_JSON>({})
  const [title, setTitle] = useState<string>('添加角色')
  const [visible, setVisible] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage()
  const [modal, contextHolderModal] = Modal.useModal()

  const confirm = (item: ANY_JSON) => {
    modal.confirm({
      title: '删除',
      icon: <svg className='icon' aria-hidden='true' width="24" height="24" style={{
        userSelect: 'none',
        pointerEvents: 'none',
        marginRight: '10px',
        color: '#fff',
      }}>
        <use xlinkHref="#icon-tip"></use>
      </svg>,
      content: '您确定删除此角色吗？',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        rolesDelete({ id: item.id }).then((res: ANY_JSON) => {
          if(res.code === 0) {
            getLists()
            messageApi.success('删除成功')
          } else {
            messageApi.error(res.message)
          }
          
        }).catch(() => {
          messageApi.error('系统错误，删除失败')
        })
      }
    })
  }


  const [page, setPage] = useState<{
    currPage: number;
    total: number;
  }>({
    currPage: 1,
    total: 0
  })
  const getLists = async (params: ANY_JSON = {}) => {
    const res = await rolesList(params)
    setPage({
      currPage: res.data.currPage,
      total: res.data.total
    })
    setLists(res.data.lists.map((i: ANY_JSON)=> {
      i.icon = 'icon-role'
      return i
    }))
  }
  

  useEffect(() => {
    getLists()
  },[])

  const onAdd = () => {
    setTitle('添加角色信息')
    setInit({})
    setVisible(true)
  }
  const onSearch = (params: ANY_JSON) => {
    getLists(params)
  }
  const onEdit = (item: ANY_JSON) => {
    setTitle('修改角色信息')
    rolesGet({id: item.id}).then((res: ANY_JSON) => {
      setInit(res.data)
      setVisible(true)
    })
    
  }
  const onDelete = (item: ANY_JSON) => {
    confirm(item)
  }

  const onOK = (item: ANY_JSON) => {
    if (init && init.id) {

      const params: ANY_JSON = {
        id: init.id
      }
      
      Object.keys(item).forEach((key) => {
        if (item[key]) {
          params[key] = item[key]
        }
      })

      rolesEdit(params).then((res: ANY_JSON) => {
        if(res.code === 0) {
          setInit({})
          messageApi.success(res.message)
          setVisible(false)
          getLists()
        } else {
          messageApi.error(res.message)
        }
      }).catch(() => {
        messageApi.error('系统错误，请稍后重试')
      })
    } else {
      rolesAdd({
        ...item
      }).then((res: ANY_JSON) => {
        if(res.code === 0) {
          setInit({})
          messageApi.success(res.message)
          setVisible(false)
          getLists()
        } else {
          messageApi.error(res.message)
        }
      }).catch(() => {
        messageApi.error('系统错误，请稍后重试')
      })
    }
  }
  const onCancel = () => {
    setVisible(false)
  }

  return <div className={styles.app}>
    {contextHolder}
    {contextHolderModal}
    <Top>
      <div>
        <Button
          type='primary'
          ghost
          onClick={onAdd}>
          <svg className='icon' aria-hidden='true' width="16" height="16" style={{
            position: 'relative',
            top: '3px',
            userSelect: 'none',
            pointerEvents: 'none',
            marginRight: '6px',
            color: '#fff',
          }}>
            <use xlinkHref="#icon-add-role"></use>
          </svg>
          新增角色
        </Button>
      </div>
    </Top>
    <ListCards
      onEdit={onEdit}
      onDelete={onDelete}
      onSearch={onSearch}
      page={page}
      list={lists}>
        {({ role_name, role_level, created_time }) => {
          return <ul style={{color: '#666', fontSize: '14px'}}>
            <li style={{marginBottom: '6px', display: 'flex'}}>
              <div style={{width: '70px'}}>角色名称：</div>
              <div>{role_name}</div>
            </li>
            <li style={{marginBottom: '6px', display: 'flex'}}>
              <div style={{width: '70px'}}>角色类型：</div>
              <div>{role_level > 0 ? '系统' : '普通'}</div>
            </li>
            <li style={{
                color: '#999',
                fontSize: '13px',
                textAlign: 'right'
              }}>
              {created_time}
            </li>
          </ul>
        }}
    </ListCards>
    <Add
      title={title}
      visible={visible}
      init={init}
      onOK={onOK}
      onCancel={onCancel}
    />
  </div>
}

export default List
