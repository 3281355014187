import X6 from './X6'
import Topbar from './Topbar'
import Attrs from './Attrs'
import Tools from './Tools'
import store from '@/store'
import { observer } from 'mobx-react'
import styles from './index.module.scss'
import Top from '@/components/Top'

function Process() {

  return <div className={styles.comp}>
    {/* <div className={styles.topbar}>
      <Topbar />
    </div> */}
    <Top>
      <Topbar />
    </Top>
    <div className={styles.box}>
      <div className={styles.tools}>
        <Tools />
      </div>
      <div className={styles.contain}>
        <X6 />
      </div>
    </div>
    {
      store.nostorage.attrs.id ?
      <div className={styles.attrs}>
        <Attrs />
      </div> :
      <></>
    }
    
  </div>
}

export default observer(Process)
