import { useEffect, useState } from 'react'
import styles from './index.module.scss'
function Back() {
  const [h, setH] = useState<number>(0)
  const [w, setW] = useState<number>(0)
  useEffect(() => {
    setH(document.body.clientHeight)
    setW(document.body.clientWidth)
  }, [])
  return <svg className={styles.comp} width={w} height={h} viewBox={`0 0 ${w} ${h}`} preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
  <g>
    <path style={{transform: `scale(${w/1920},${h/1080})`}} id="svg5" d="m-2.65971,-2.66274l2.65971,1084.00399l1793.34646,1.33334c0.00696,0.00394 -1104.00111,-270.66472 -1133.33466,-529.33327c-29.33355,-258.66855 196.00144,-252.00184 182.668,-388.00283c-13.33343,-136.00099 -142.68165,-166.67578 -142.67468,-168.00518l-702.66483,0.00395z" opacity="1" stroke="#5cffbb" fill="#5cffbb"/>
  </g>
</svg>
}

export default Back
