import { SM4 } from '@clownlee/utils'
import { ascDecode } from '.'

enum MODE {
  cbc = 'cbc',
  ecb = 'ecb',
}

enum CIPHER_TYPE {
  base64 = 'base64',
  text = 'text',
}

type CONFIGURE = {
  key: string;
  mode: MODE;
  iv: string;
  cipherType: CIPHER_TYPE;
}

class Sm4 {
  private sm4Config: CONFIGURE;
  private sm4: any;

  constructor() {
    
    this.sm4Config = {
      // encrypt/decypt main key; cannot be omitted
      key: ascDecode([535, 270, 430, 235, 585, 495, 375, 390, 255, 565, 350, 245, 250, 370, 575, 585]),

      // optional; can be 'cbc' or 'ecb'
      mode: MODE.cbc, // default

      // optional; when use cbc mode, it's necessary
      iv: ascDecode([480, 265, 505, 350, 270, 370, 330, 275, 545, 385, 270, 370, 595, 495, 275, 585]), // default is null

      // optional: this is the cipher data's type; Can be 'base64' or 'text'
      cipherType: CIPHER_TYPE.base64 // default is base64
    }

    this.sm4 = new SM4(this.sm4Config as CONFIGURE as any);
  }

  /**
   * 加密
   * @param data 加密前的数据
   * @returns 加密后的字符串
   */
  public encrypt(data: any) {
    return this.sm4.encrypt(JSON.stringify(data))
  }

  /**
   * 解密
   * @param str 加密的字符串
   * @returns 解密的数据
   */
  public decrypt(str: string) {
    return JSON.parse(this.sm4.decrypt(str))
  }
}

export default Sm4
