import { ReactNode } from 'react'
import { RouteObject } from 'react-router-dom'
import _ from 'lodash'

const modules = import.meta.glob('@/pages/**/index.json', { eager: true, import: 'default' })
const comps = import.meta.glob('@/pages/**/index.tsx', { eager: true, import: 'default' })

const routes: (RouteObject & ANY_JSON)[] = Object.keys(modules).map((item: string) => {
  const path = item.replace(/(^\/src\/pages)|(\/index.json$)/g, '').toLocaleLowerCase()
  const pathArr = path.split('/')
  let arrPid = _.cloneDeep(pathArr).filter((item) => item)
  arrPid = arrPid.slice(0, arrPid.length - 1)
  return {
    id: pathArr[pathArr.length - 1],
    pid: arrPid.join('/'),
    path: path === '/root'? '/' : path,
    element: comps[item.replace(/(\/index.json$)/g, '/index.tsx')] as ReactNode,
    ...modules[item] as ANY_JSON
  }
}) as RouteObject[]

export default routes
