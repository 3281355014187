import styles from './index.module.scss'
import { Dropdown, Input, Pagination } from 'antd'

const { Search } = Input;

interface PROPS {
  currPage: number;
  pageSize: number;
  total: number;
  onChange: Function;
  onSearch: Function;
}

function ListBar(props: PROPS) {
  const changePage = (currPage: number) => {
    props.onChange(currPage)
  }

  const onSearch = (val: string) => {
    props.onSearch(val)
  }

  return (
    <Dropdown
      dropdownRender={() => (
        <div className={styles.contain}>
          <div className={styles.search}>
            <Search
              allowClear
              placeholder="请输入内容"
              onSearch={onSearch}
              enterButton />
          </div>
          <div className={styles.pagination}>
            <Pagination simple current={props.currPage} pageSize={props.pageSize} total={props.total} showSizeChanger={false} onChange={changePage}></Pagination>
            <span>共{props.total}条</span>
          </div>
        </div>
      )}
      placement="top"
      arrow>
      <div
        className={styles.comp}>
          <div>
            <svg className='icon' aria-hidden='true' width="18" height="18" style={{
              position: 'relative',
              top: '3px',
              userSelect: 'none',
              pointerEvents: 'none',
              marginRight: '6px',
            }}>
              <use xlinkHref="#icon-controller"></use>
            </svg>
            <span>操作</span>
          </div>
      </div>
    </Dropdown>
  )
}

export default ListBar
