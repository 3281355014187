import styles from './index.module.scss'
import Top from '@/components/Top'
import { Button } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'

function Editor() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const onAdd = () => {}

  return <div className={styles.app}>
    <Top>
      <>
        <div>{searchParams.get('application_name')}</div>
        <div>
          <Button
            type='primary'
            ghost
            style={{marginRight: '10px'}}
            onClick={() => navigate(-1)} danger>
            <svg className='icon' aria-hidden='true' width="16" height="16" style={{
              position: 'relative',
              top: '3px',
              userSelect: 'none',
              pointerEvents: 'none',
              marginRight: '6px',
              color: '#fff',
            }}>
              <use xlinkHref="#icon-back"></use>
            </svg>
            返回
          </Button>
          <Button
            type='primary'
            ghost
            onClick={onAdd}>
            <svg className='icon' aria-hidden='true' width="16" height="16" style={{
              position: 'relative',
              top: '3px',
              userSelect: 'none',
              pointerEvents: 'none',
              marginRight: '6px',
              color: '#fff',
            }}>
              <use xlinkHref="#icon-save"></use>
            </svg>
            保存
          </Button>
        </div>
      </>
    </Top>
  </div>
}

export default Editor
