import axios from '@/utils/axios'
export const login = (data: FieldType) => {
  return axios.post('/members/login', data)
}

export const membersList = (data: ANY_JSON) => {
  return axios.get('/members/list', {
    params: data,
    headers: {
      sign: true
    }
  })
}

export const membersGet = (data: { id: number }) => {
  return axios.get('/members/get', {
    params: data,
    headers: {
      sign: true
    }
  })
}

export const membersEdit = (data: ANY_JSON) => {
  return axios.post('/members/edit', data, {
    headers: {
      sign: true
    }
  })
}

export const membersAdd = (data: ANY_JSON) => {
  return axios.post('/members/add', data, {
    headers: {
      sign: true
    }
  })
}

export const membersDelete = (data: { id: number }) => {
  return axios.post('/members/delete', data, {
    headers: {
      sign: true
    }
  })
}