import { ReactNode, useState } from 'react'
import { Image } from 'antd'
import ListBar from '@/components/ListCards/ListBar'
import styles from './index.module.scss'

type PROPS = {
  list: any[];
  page: {
    currPage: number;
    total: number;
  };
  children: (item: ANY_JSON) => ReactNode;
  width?: number;
  tools?: (item: ANY_JSON) => ReactNode;
  onExport?: (...args: any) => void;
  onEdit?:  (...args: any) => void;
  onDelete?: (...args: any) => void;
  onSearch?: (...args: any) => void;
}

function ListCards(props: PROPS) {
  const [ pageSize ] = useState(60)
  const [search, setSearch] = useState<string>('')


  const onSearch = (val: string) => {
    setSearch(val)
    props.onSearch && props.onSearch({
      search: val,
      pageSize,
      ...props.page,
      currPage: 1
    })
  }
  const onChange = (currPage: number) => {
    props.onSearch && props.onSearch({
      search,
      pageSize,
      ...props.page,
      currPage
    })
  }

  return <div className={styles.comp}>
    {
      props.list.map((item) => {
        return <div
          className={styles.item}
          style={{minWidth: `${props.width || 260}px`, maxWidth: `${props.width || 260}px`}}
          key={item.id}>
          <div
            className={styles.itemContent}>
            <div style={{
              width: '50px',
              marginRight: '10px',
            }}>
              {/^(http|https):\/\/\S*/.test(item.icon) ?
              <Image
                src={item.icon}
                width='50px'
                height='50px'
                style={{objectFit: 'cover'}}
              /> :
              <svg className='icon' aria-hidden='true' width="40" height="40" style={{
                userSelect: 'none',
                pointerEvents: 'none',
                color: '#fff',
              }}>
                <use xlinkHref={`#${item.icon}`}></use>
              </svg>}
              
            </div>
            <div style={{ flex: 1 }}>
              {props.children(item)}
            </div>  
          </div>
          <div className={styles.btn}>
            {props.tools && props.tools(item)}
            {props.onExport ? <div onClick={() => props.onExport && props.onExport(item)} className={styles.btnItem}>
              <svg className='icon' aria-hidden='true' width="24" height="24" style={{
                userSelect: 'none',
                pointerEvents: 'none',
                marginRight: '10px',
                color: '#fff',
              }}>
                <use xlinkHref='#icon-export'></use>
              </svg>
            </div>: <></>}

            {props.onEdit ? <div onClick={() => props.onEdit && props.onEdit(item)} className={styles.btnItem}>
              <svg className='icon' aria-hidden='true' width="24" height="24" style={{
                userSelect: 'none',
                pointerEvents: 'none',
                marginRight: '10px',
                color: '#fff',
              }}>
                <use xlinkHref='#icon-edit'></use>
              </svg>
            </div> : <></>}

            {props.onDelete ? <div onClick={() => props.onDelete && props.onDelete(item)} className={styles.btnItem}>
              <svg className='icon' aria-hidden='true' width="24" height="24" style={{
                userSelect: 'none',
                pointerEvents: 'none',
                color: '#fff',
              }}>
                <use xlinkHref='#icon-delete'></use>
              </svg>
            </div> : <></>}

          </div>
        </div>
      })
    }
    <ListBar currPage={props.page.currPage} pageSize={pageSize} total={props.page.total} onSearch={onSearch}  onChange={onChange}></ListBar>
  </div>
}

export default ListCards
