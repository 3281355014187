import { useEffect, useState } from 'react'
import styles from './index.module.scss'
function Login (props: { scale: number }) {
  const [wh, setWh] = useState<number>(0)
  useEffect(() => {
    setWh(90 * (props.scale || 1))
  }, [])
  console.log(props.scale, wh)
  return <svg className={styles.comp} xmlns="http://www.w3.org/2000/svg" width={wh} height={wh} viewBox={`0 0 ${wh} ${wh}`}>
    <g>
      <path style={{transform: `scale(${props.scale || 1})`}} fill="#ffb000" d="M29.44,48.5a6.65,6.65,0,0,1,6.62-7.22,6.65,6.65,0,0,1,6.61,7.22H48.5V40.77a1.5,1.5,0,0,1,2.19-1.33,3.71,3.71,0,0,0,1.4.27,3.64,3.64,0,0,0,3.64-3.64h0a3.65,3.65,0,0,0-1.07-2.58,3.6,3.6,0,0,0-2.57-1.06,3.64,3.64,0,0,0-.76.07,4.88,4.88,0,0,0-.65.2,1.5,1.5,0,0,1-2-.66,1.45,1.45,0,0,1-.16-.68V23.66A26.38,26.38,0,0,0,23.66,48.5h5.78Z" transform="translate(-6.52 -6.52)"/>
      <path style={{transform: `scale(${props.scale || 1})`}} fill="#fff" d="M39.44,49.31a3.74,3.74,0,0,0,.27-1.39,3.66,3.66,0,0,0-1.07-2.58,3.64,3.64,0,0,0-6.22,2.58,3.77,3.77,0,0,0,.07.76,4,4,0,0,0,.2.65,1.49,1.49,0,0,1-.66,2,1.45,1.45,0,0,1-.68.16H23.66A26.36,26.36,0,0,0,48.5,76.34V70.56a6.63,6.63,0,0,1-5.28-1.92l-.09-.09a6.64,6.64,0,0,1,5.36-11.22V51.51H40.76a1.5,1.5,0,0,1-1.5-1.5,1.57,1.57,0,0,1,.17-.7Z" transform="translate(-6.52 -6.52)"/>
      <path style={{transform: `scale(${props.scale || 1})`}} fill="#ffb000" d="M49.32,60.57a3.68,3.68,0,0,0-1.4-.27,3.62,3.62,0,0,0-3.64,3.62v0a3.64,3.64,0,0,0,3.64,3.65,3.77,3.77,0,0,0,.76-.08,3.44,3.44,0,0,0,.65-.2,1.5,1.5,0,0,1,2,.67,1.41,1.41,0,0,1,.16.67v7.69A26.36,26.36,0,0,0,76.34,51.51H70.56a6.64,6.64,0,0,1-11.32,5.28l-.08-.09a6.62,6.62,0,0,1-1.84-5.19H51.5v7.74a1.5,1.5,0,0,1-1.5,1.5,1.46,1.46,0,0,1-.68-.17Z" transform="translate(-6.52 -6.52)"/>
      <path style={{transform: `scale(${props.scale || 1})`}} fill="#fff" d="M60.57,50.7a3.64,3.64,0,1,0,7,1.38,3.74,3.74,0,0,0-.07-.75,4.6,4.6,0,0,0-.2-.66,1.49,1.49,0,0,1,.66-2,1.45,1.45,0,0,1,.68-.16h7.69A26.36,26.36,0,0,0,51.5,23.66v5.78a6.65,6.65,0,1,1,0,13.24V48.5h7.73a1.5,1.5,0,0,1,1.33,2.19Z" transform="translate(-6.52 -6.52)"/>
      <path style={{transform: `scale(${props.scale || 1})`}} fill="#ffb000" d="M86.42,45.17A1.49,1.49,0,0,1,85,43.92a35.34,35.34,0,0,0-6-14.39,1.52,1.52,0,0,1,0-1.78L83.53,22,78,16.47l-5.68,4.4a1.5,1.5,0,0,1-1.82.07,35.09,35.09,0,0,0-6.87-3.79h0A35.17,35.17,0,0,0,56.08,15a1.51,1.51,0,0,1-1.23-1.29l-.92-7.17H46.07l-.9,7.06A1.49,1.49,0,0,1,43.92,15a35.69,35.69,0,0,0-7.51,2.18,34.65,34.65,0,0,0-6.88,3.79,1.52,1.52,0,0,1-1.78,0L22,16.47,16.47,22l4.39,5.68a1.49,1.49,0,0,1,.08,1.82,35.48,35.48,0,0,0-6,14.39,1.49,1.49,0,0,1-1.28,1.23l-7.17.92v7.86l7.06.9A1.49,1.49,0,0,1,15,56.08a35.69,35.69,0,0,0,2.18,7.51,34.65,34.65,0,0,0,3.79,6.88,1.52,1.52,0,0,1,0,1.78L16.47,78,22,83.53l5.68-4.39a1.49,1.49,0,0,1,1.82-.08,35.48,35.48,0,0,0,14.39,6,1.49,1.49,0,0,1,1.23,1.29l.92,7.16h7.86l.9-7.06A1.49,1.49,0,0,1,56.08,85a35.69,35.69,0,0,0,7.51-2.18,34.65,34.65,0,0,0,6.88-3.79,1.52,1.52,0,0,1,1.78,0L78,83.53,83.53,78,79.14,72.3a1.49,1.49,0,0,1-.08-1.82,35.58,35.58,0,0,0,6-14.4,1.49,1.49,0,0,1,1.28-1.23l7.17-.92V46.07ZM50,79.06A29.06,29.06,0,1,1,79.06,50,29.06,29.06,0,0,1,50,79.06Z" transform="translate(-6.52 -6.52)"/>
    </g>
  </svg>
}

export default Login
