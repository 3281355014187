import React from 'react'
import styles from './index.module.scss'
import Top from '@/components/Top'

class Root extends React.Component<ANY_JSON> {
  constructor(props: ANY_JSON) {
    super(props)
  }
  
  render() {
    return <div className={styles.app}>
      <Top />
    </div>
  }
}

export default Root
