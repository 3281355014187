import { useEffect, useRef } from 'react';
import styles from './index.module.scss'
import { EditorView } from '@codemirror/view'
import { basicSetup } from  'codemirror'
import { javascript } from '@codemirror/lang-javascript'
import { json } from '@codemirror/lang-json'
import { oneDark } from '@codemirror/theme-one-dark'
import bus from '@/utils/bus'
import { EditorState } from '@codemirror/state';

interface ICodeMirror {
  value?: string;
  onChange: (content: string) => void;
}

function Editor(props: ICodeMirror) {
  const editor = useRef<EditorView>()
  const contain = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!contain || !contain.current) {
      return;
    }

    const startState = EditorState.create({
      doc: props.value,
      extensions: [
        oneDark,
        basicSetup,
        javascript(),
        json(),
        EditorView.updateListener.of((v) => {
          if (v.docChanged) {
            props.onChange(v.state.toJSON().doc);
          }
        }),
      ],
    })
    editor.current = new EditorView({
      state: startState,
      parent: contain.current as any
    })
  
    bus.emit('editor', editor.current)
    return () => editor.current!.destroy()
  }, [contain])

  return (
    <div ref={contain} className={styles.comp}></div>
  );
}
 
export default Editor;