import menus from '@/routes'
import { ReactNode } from 'react'
import { BrowserRouter, Routes, Route, RouteObject } from 'react-router-dom'
import { observer } from 'mobx-react';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        { menus.map((item: (RouteObject & ANY_JSON)) => {
          const Comp = item.element as ReactNode as any
          return (
            <Route key={item.id} path={item.path} element={<Comp />} />
          )
        }) }
      </Routes>
    </BrowserRouter>
  )
}

export default observer(App)
