import axios from '@/utils/axios'

export const processesList = (data: ANY_JSON) => {
  return axios.get('/processes/list', {
    params: data,
    headers: {
      sign: true
    }
  })
}

export const processesAdd = (data: ANY_JSON) => {
  return axios.post('/processes/add', data, {
    headers: {
      sign: true
    }
  })
}