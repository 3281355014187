import styles from './index.module.scss'
import { Button, Modal, Form, Input, message, Upload } from 'antd'
import type { UploadFile, UploadProps } from 'antd';
import Top from '@/components/Top'
import ListCards from '@/components/ListCards'
import { useEffect, useState } from 'react'
import { organizationsAdd, organizationsEdit, organizationsList, organizationsGet, organizationsDelete } from '@/apis/organization'
import { commonUpload } from '@/apis/common'

type ADD_PROPS = {
  title: string;
  visible: boolean;
  onOK: (item: ANY_JSON) => void;
  onCancel: () => void;
  init?: ANY_JSON;
}

type FieldType = {
  org_name: string;
  org_logo: string;
  introduction: string;
}
const Add = (props: ADD_PROPS) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as any);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onUpload = async (options: any) => {
    const { onSuccess, file } = options

    const result = await commonUpload(file)

    onSuccess({
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: result.data.url as string
    }, file)
  }
  
  useEffect(() => {
    if (props.visible) {
      props.init && form.setFieldsValue(props.init)
      props.init && setFileList([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: props.init.org_logo
        }
      ])
    }
    
  }, [
    props.visible
  ])
  
  const handleOk = () => {
    form.validateFields().then((res) => {
      delete res.confirmPassword
      res.org_logo = fileList[0].response.url || fileList[0].url
      props.onOK && props.onOK(res)
    })
  }

  const handleCancel = () => {
    form.resetFields()
    props.onCancel && props.onCancel()
  }

  return (
    <Modal
      title={props.title}
      open={props.visible}
      maskClosable={false}
      onOk={handleOk}
      onCancel={handleCancel}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label="组织名称："
            name="org_name"
            rules={[{ required: true, message: '请输入组织名称!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label="组织LOGO："
            name="org_logo"
            rules={[{ required: true, message: '请输入组织LOGO!' }]}
          >
            {/* <Input /> */}
            <Upload
              accept=".jpeg,.jpg,.png"
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              customRequest={onUpload}
            >
              {fileList.length < 1 && '上传LOGO'}
            </Upload>
          </Form.Item>
          <Form.Item<FieldType>
            label="组织简介："
            name="introduction"
            rules={[{ required: true, message: '请输入组织简介!' }]}
          >
            <Input.TextArea
              rows={5}
              maxLength={600} />
          </Form.Item>
        </Form>
    </Modal>
  );
}

function List() {
  const [lists, setLists] = useState<ANY_JSON[]>([])
  const [init, setInit] = useState<ANY_JSON>({})
  const [title, setTitle] = useState<string>('添加组织')
  const [visible, setVisible] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage()
  const [modal, contextHolderModal] = Modal.useModal()

  const confirm = (item: ANY_JSON) => {
    modal.confirm({
      title: '删除',
      icon: <svg className='icon' aria-hidden='true' width="24" height="24" style={{
        userSelect: 'none',
        pointerEvents: 'none',
        marginRight: '10px',
        color: '#fff',
      }}>
        <use xlinkHref="#icon-tip"></use>
      </svg>,
      content: '您确定删除此组织吗？',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        organizationsDelete({ id: item.id }).then((res: ANY_JSON) => {
          if(res.code === 0) {
            getLists()
            messageApi.success('删除成功')
          } else {
            messageApi.error(res.message)
          }
          
        }).catch(() => {
          messageApi.error('系统错误，删除失败')
        })
      }
    })
  }


  const [page, setPage] = useState<{
    currPage: number;
    total: number;
  }>({
    currPage: 1,
    total: 0
  })
  const getLists = async (params: ANY_JSON = {}) => {
    const res = await organizationsList(params)
    setPage({
      currPage: res.data.currPage,
      total: res.data.total
    })
    setLists(res.data.lists.map((i: ANY_JSON)=> {
      i.icon = i.org_logo ? i.org_logo :'icon-org'
      return i
    }))
  }
  

  useEffect(() => {
    getLists()
  },[])

  const onAdd = () => {
    setTitle('添加组织信息')
    setInit({})
    setVisible(true)
  }
  const onSearch = (params: ANY_JSON) => {
    getLists(params)
  }
  const onEdit = (item: ANY_JSON) => {
    console.log(item)
    setTitle('修改组织信息')
    organizationsGet({id: item.id}).then((res: ANY_JSON) => {
      setInit(res.data)
      setVisible(true)
    })
    
  }
  const onDelete = (item: ANY_JSON) => {
    confirm(item)
  }

  const onOK = (item: ANY_JSON) => {
    if (init && init.id) {

      const params: ANY_JSON = {
        id: init.id
      }
      
      Object.keys(item).forEach((key) => {
        if (item[key]) {
          params[key] = item[key]
        }
      })

      organizationsEdit(params).then((res: ANY_JSON) => {
        if(res.code === 0) {
          setInit({})
          messageApi.success(res.message)
          setVisible(false)
          getLists()
        } else {
          messageApi.error(res.message)
        }
      }).catch(() => {
        messageApi.error('系统错误，请稍后重试')
      })
    } else {
      organizationsAdd(item).then((res: ANY_JSON) => {
        if(res.code === 0) {
          setInit({})
          messageApi.success(res.message)
          setVisible(false)
          getLists()
        } else {
          messageApi.error(res.message)
        }
      }).catch(() => {
        messageApi.error('系统错误，请稍后重试')
      })
    }
  }
  const onCancel = () => {
    setVisible(false)
  }

  return <div className={styles.app}>
    {contextHolder}
    {contextHolderModal}
    <Top>
      <div>
        <Button
          type='primary'
          ghost
          onClick={onAdd}>
          <svg className='icon' aria-hidden='true' width="16" height="16" style={{
            position: 'relative',
            top: '3px',
            userSelect: 'none',
            pointerEvents: 'none',
            marginRight: '6px',
            color: '#fff',
          }}>
            <use xlinkHref="#icon-add-org"></use>
          </svg>
          新增组织
        </Button>
      </div>
    </Top>
    <ListCards
      width={360}
      onEdit={onEdit}
      onDelete={onDelete}
      onSearch={onSearch}
      page={page}
      list={lists}>
        {({ org_name, introduction, created_time }) => {
          return <ul style={{color: '#666', fontSize: '14px'}}>
            <li style={{marginBottom: '6px', display: 'flex'}}>
              <div style={{width: '70px'}}>组织名称：</div>
              <div style={{
                flex: 1,
              }}>{org_name}</div>
            </li>
            <li style={{marginBottom: '6px', display: 'flex'}}>
              <div>组织简介：</div>
              <div style={{ flex: 1, textAlign: 'justify', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3, }}>{introduction}</div>
            </li>
            <li style={{
                color: '#999',
                fontSize: '13px',
                textAlign: 'right'
              }}>
              {created_time}
            </li>
          </ul>
        }}
    </ListCards>
    <Add
      title={title}
      visible={visible}
      init={init}
      onOK={onOK}
      onCancel={onCancel}
    />
  </div>
}

export default List
