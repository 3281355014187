import styles from './index.module.scss'
import { getId } from '@/utils'
import { Button, Modal, Form, Input, message } from 'antd'
import Top from '@/components/Top'
import { useNavigate } from 'react-router-dom'
import ListCards from '@/components/ListCards'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { applicationsAdd, applicationsEdit, applicationsList, applicationsGet, applicationsDelete } from '@/apis/application'


type ADD_PROPS = {
  title: string;
  visible: boolean;
  onOK: (item: ANY_JSON) => void;
  onCancel: () => void;
  init?: ANY_JSON;
}

type FieldType = {
  application_key: string;
  application_name: string;
  configure: string;
}

const Add = (props: ADD_PROPS) => {
  const [form] = Form.useForm()
  
  useEffect(() => {
    if (props.visible) {
      props.init && form.setFieldsValue(props.init)
    }
    
  }, [
    props.visible
  ])
  
  const handleOk = () => {
    form.validateFields().then((res) => {
      delete res.confirmPassword
      props.onOK && props.onOK(res)
    })
  }

  const handleCancel = () => {
    form.resetFields()
    props.onCancel && props.onCancel()
  }

  return (
    <Modal
      title={props.title}
      open={props.visible}
      maskClosable={false}
      onOk={handleOk}
      onCancel={handleCancel}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label="应用名称："
            name="application_name"
            rules={[{ required: true, message: '请输入应用名称!' }]}
          >
            <Input />
          </Form.Item>
          
        </Form>
    </Modal>
  );
}

function List() {
  const [lists, setLists] = useState<ANY_JSON[]>([])
  const [init, setInit] = useState<ANY_JSON>({})
  const [title, setTitle] = useState<string>('添加应用')
  const [visible, setVisible] = useState<boolean>(false)
  const [messageApi, contextHolder] = message.useMessage()
  const [modal, contextHolderModal] = Modal.useModal()
  const navigate = useNavigate()

  const confirm = (item: ANY_JSON) => {
    modal.confirm({
      title: '删除',
      icon: <svg className='icon' aria-hidden='true' width="24" height="24" style={{
        userSelect: 'none',
        pointerEvents: 'none',
        marginRight: '10px',
        color: '#fff',
      }}>
        <use xlinkHref="#icon-tip"></use>
      </svg>,
      content: '您确定删除此应用吗？',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        applicationsDelete({ id: item.id }).then((res: ANY_JSON) => {
          if(res.code === 0) {
            getLists()
            messageApi.success('删除成功')
          } else {
            messageApi.error(res.message)
          }
          
        }).catch(() => {
          messageApi.error('系统错误，删除失败')
        })
      }
    })
  }


  const [page, setPage] = useState<{
    currPage: number;
    total: number;
  }>({
    currPage: 1,
    total: 0
  })
  
  const getLists = async (params: ANY_JSON = {}) => {
    const res = await applicationsList(params)
    setPage({
      currPage: res.data.currPage,
      total: res.data.total
    })
    setLists(res.data.lists.map((i: ANY_JSON)=> {
      i.icon = 'icon-app'
      return i
    }))
  }

  useEffect(() => {
    getLists()
  },[])

  const onAdd = () => {
    setTitle('添加应用信息')
    setInit({
      application_name: ''
    })
    setVisible(true)
  }
  const onSearch = (params: ANY_JSON) => {
    getLists(params)
  }
  const onEdit = (item: ANY_JSON) => {
    setTitle('修改应用信息')
    applicationsGet({id: item.id}).then((res: ANY_JSON) => {
      setInit(res.data)
      setVisible(true)
    })
  }
  const onDelete = (item: ANY_JSON) => {
    confirm(item)
  }

  const onOK = (item: ANY_JSON) => {
    if (init && init.id) {

      const params: ANY_JSON = {
        id: init.id
      }
      
      Object.keys(item).forEach((key) => {
        if (item[key]) {
          params[key] = item[key]
        }
      })

      applicationsEdit(params).then((res: ANY_JSON) => {
        if(res.code === 0) {
          messageApi.success(res.message)
          setVisible(false)
          getLists()
        } else {
          messageApi.error(res.message)
        }
      }).catch(() => {
        messageApi.error('系统错误，请稍后重试')
      })
    } else {
      const params: ANY_JSON = {
        ...item,
        application_key: getId()
      }
      applicationsAdd(params).then((res: ANY_JSON) => {
        if(res.code === 0) {
          messageApi.success(res.message)
          setVisible(false)
          getLists()
        } else {
          messageApi.error(res.message)
        }
      }).catch(() => {
        messageApi.error('系统错误，请稍后重试')
      })
    }
  }
  const onCancel = () => {
    setVisible(false)
  }

  return <div className={styles.app}>
    {contextHolder}
    {contextHolderModal}
    <Top>
      <div>
        <Button
          type='primary'
          ghost
          onClick={onAdd}>
          <svg className='icon' aria-hidden='true' width="16" height="16" style={{
            position: 'relative',
            top: '3px',
            userSelect: 'none',
            pointerEvents: 'none',
            marginRight: '6px',
            color: '#fff',
          }}>
            <use xlinkHref="#icon-add"></use>
          </svg>
          新增应用
        </Button>
      </div>
    </Top>
    <ListCards
      onEdit={onEdit}
      onDelete={onDelete}
      onSearch={onSearch}
      width={410}
      tools={(item: any) => {
        return <>
          <div onClick={() => {
            const params = {
              application_id: item.id,
              application_key: item.application_key,
              application_name: item.application_name
            }
            navigate(`/application/process/list?${qs.stringify(params)}`)
          }} className={styles.btnItem}>
            <svg className='icon' aria-hidden='true' width="24" height="24" style={{
              userSelect: 'none',
              pointerEvents: 'none',
              marginRight: '10px',
              color: '#fff',
            }}>
              <use xlinkHref='#icon-process'></use>
            </svg>
          </div>
          <div onClick={() => {
            const params = {
              application_id: item.id,
              application_key: item.application_key,
              application_name: item.application_name
            }
            navigate(`/application/page/list?${qs.stringify(params)}`)
          }} className={styles.btnItem}>
            <svg className='icon' aria-hidden='true' width="24" height="24" style={{
              userSelect: 'none',
              pointerEvents: 'none',
              marginRight: '10px',
              color: '#fff',
            }}>
              <use xlinkHref='#icon-page'></use>
            </svg>
          </div>
        </>
      }}
      page={page}
      list={lists}>
        {({ application_name, application_key, created_time }) => {
          return <ul style={{color: '#666', fontSize: '14px'}}>
            <li style={{marginBottom: '6px'}}>
              <span>应用名称：</span>
              <span>{application_name}</span>
            </li>
            <li style={{marginBottom: '6px'}}>
              <span>应用KEY：</span>
              <span>{application_key}</span>
            </li>
            <li style={{
                color: '#999',
                fontSize: '13px',
                textAlign: 'right'
              }}>
              {created_time}
            </li>
          </ul>
        }}
    </ListCards>
    <Add
      title={title}
      visible={visible}
      init={init}
      onOK={onOK}
      onCancel={onCancel}
    />
  </div>
}

export default List
