import axios from 'axios'
import store from '@/store'
import Sm4 from '@/utils/sm4'

const sm4 = new Sm4()

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_DOMAIN + import.meta.env.VITE_API_PREFIX,
  timeout: 30000,
  headers: {
    'Content-Type': 'text/plain;charset=UTF-8'
  }
})

instance.interceptors.request.use((config) => {
  if (!config.data) {
    // 解决请求没有参数时添加不上Content-Type问题
    config.data = true
  }
  if (config.headers.sign as any) {
    delete config.headers.sign
    const token = store.state.token
    token && (config.headers.Authorization = `Bearer ${token}`)
  }

  if(config.method === 'post' && config.headers['Content-Type'] === 'text/plain;charset=UTF-8') {
    config.data = sm4.encrypt(config.data)
  }
  if(config.method === 'get') {
    config.params = {
      params: sm4.encrypt(config.params)
    }
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
  if (response.status === 200) {    
    response.data = sm4.decrypt(response.data)
    console.log(response.data)
    return Promise.resolve(response.data)
  } else {
    return Promise.reject(response)
  }
}, (error) => {
  console.log(error)
  if (error.response.status) {
    if (error.response.status === 403) {
      store.setAttrs()
      store.setToken()
      window.location.href = '/login'
    }
  }
})

export default instance
