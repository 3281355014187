import bus from '@/utils/bus'
import styles from './index.module.scss'
import { observer, inject } from 'mobx-react'
import React from 'react'

@inject("store") // 注入store
@observer
class Tools extends React.Component {
  render() {
    const { store: { state } } = this.props as any
    return <div className={styles.comp}>
      <ul>
        {/* {state.x6InitNodes.filter((i: X6NODE) => i.id !== 'start').map((item: any) => { */}
        {state.x6InitNodes.map((item: any) => {
          return <li
            key={item.id}
            className={styles.li}>
            <div
              data-id={item.id}
              draggable={true}
              className={styles.customReactNode}
              onDragStart={() => {

              }}
              onDrag={() => {
                
              }}
              onDragEnd={(e) => {
                const item = e.target as any
                bus.emit('x6.addNodeId', item.dataset)
              }}>
              <svg className='icon' aria-hidden='true' width="18" height="18" style={{
                marginRight: '6px',
                position: 'relative',
                top: '1px',
                userSelect: 'none',
                pointerEvents: 'none'
              }}>
                <use xlinkHref={`#${item.icon}`}></use>
              </svg>
              <div>{item.name}</div>
            </div>
          </li>
        })}
      </ul>
    </div>
  }
}

export default Tools
