import axios from '@/utils/axios'

export const organizationsList = (data: ANY_JSON) => {
  return axios.get('/organizations/list', {
    params: data,
    headers: {
      sign: true
    }
  })
}

export const organizationsGet = (data: { id: number }) => {
  console.log('1',data)
  return axios.get('/organizations/get', {
    params: data,
    headers: {
      sign: true
    }
  })
}

export const organizationsEdit = (data: ANY_JSON) => {
  return axios.post('/organizations/edit', data, {
    headers: {
      sign: true
    }
  })
}

export const organizationsAdd = (data: ANY_JSON) => {
  return axios.post('/organizations/add', data, {
    headers: {
      sign: true
    }
  })
}

export const organizationsDelete = (data: { id: number }) => {
  return axios.post('/organizations/delete', data, {
    headers: {
      sign: true
    }
  })
}