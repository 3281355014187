import axios from '@/utils/axios'

export const applicationsList = (data: ANY_JSON) => {
  return axios.get('/applications/list', {
    params: data,
    headers: {
      sign: true
    }
  })
}

export const applicationsGet = (data: { id: number }) => {
  return axios.get('/applications/get', {
    params: data,
    headers: {
      sign: true
    }
  })
}

export const applicationsEdit = (data: ANY_JSON) => {
  return axios.post('/applications/edit', data, {
    headers: {
      sign: true
    }
  })
}

export const applicationsAdd = (data: ANY_JSON) => {
  return axios.post('/applications/add', data, {
    headers: {
      sign: true
    }
  })
}

export const applicationsDelete = (data: { id: number }) => {
  return axios.post('/applications/delete', data, {
    headers: {
      sign: true
    }
  })
}