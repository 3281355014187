import axios from '@/utils/axios'

export const rolesList = (data: ANY_JSON) => {
  return axios.get('/roles/list', {
    params: data,
    headers: {
      sign: true
    }
  })
}

export const rolesGet = (data: { id: number }) => {
  return axios.get('/roles/get', {
    params: data,
    headers: {
      sign: true
    }
  })
}

export const rolesEdit = (data: ANY_JSON) => {
  return axios.post('/roles/edit', data, {
    headers: {
      sign: true
    }
  })
}

export const rolesAdd = (data: ANY_JSON) => {
  return axios.post('/roles/add', data, {
    headers: {
      sign: true
    }
  })
}

export const rolesDelete = (data: { id: number }) => {
  return axios.post('/roles/delete', data, {
    headers: {
      sign: true
    }
  })
}

export const rolesListAll = (data = {}) => {
  return axios.get('/roles/list-all', {
    params: data,
    headers: {
      sign: true
    }
  })
}