import styles from './index.module.scss'
import { Button } from 'antd'
import Top from '@/components/Top'
import ListCards from '@/components/ListCards'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { pagesList } from '@/apis/page'

function List() {
  const [lists, setLists] = useState<ANY_JSON[]>([])
  const [page, setPage] = useState<{
    currPage: number;
    total: number;
  }>({
    currPage: 1,
    total: 0
  })
  const getLists = async (params: ANY_JSON = {}) => {
    params.application_id = searchParams.get('application_id')
    const res = await pagesList(params)
    setPage({
      currPage: res.data.currPage,
      total: res.data.total
    })
    setLists(res.data.lists.map((i: ANY_JSON)=> {
      i.icon = 'icon-page'
      return i
    }))
  }

  useEffect(() => {
    getLists()
  },[])
  
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const gotoAdd = () => {
    navigate('/application/page/editor?application_id=' + searchParams.get('application_id') + '&application_name=' + searchParams.get('application_name') + '&application_key=' + searchParams.get('application_key'))
  }

  const onEdit = () => {
    navigate('/application/page/editor?application_id=' + searchParams.get('application_id') + '&application_name=' + searchParams.get('application_name') + '&application_key=' + searchParams.get('application_key'))
  }
  const onDelete = () => {}

  return <div className={styles.app}>
    <Top>
      <>
        <div>{searchParams.get('application_name')}</div>
        <div>
          <Button
            type='primary'
            ghost
            onClick={()=> {
              navigate(-1)
            }}
            danger
            style={{ marginRight: '10px' }}>
            <svg className='icon' aria-hidden='true' width="16" height="16" style={{
              position: 'relative',
              top: '3px',
              userSelect: 'none',
              pointerEvents: 'none',
              marginRight: '6px',
              color: '#fff',
            }}>
              <use xlinkHref="#icon-back"></use>
            </svg>
            返回
          </Button>
          <Button
            type='primary'
            ghost
            onClick={gotoAdd}>
            <svg className='icon' aria-hidden='true' width="16" height="16" style={{
              position: 'relative',
              top: '3px',
              userSelect: 'none',
              pointerEvents: 'none',
              marginRight: '6px',
              color: '#fff',
            }}>
              <use xlinkHref="#icon-add"></use>
            </svg>
            新增页面
          </Button>
        </div>
      </>
    </Top>
    <ListCards
      onEdit={onEdit}
      onDelete={onDelete}
      onSearch={(item) => {
        getLists(item)
      }}
      page={page}
      list={lists}>
        {({ username, account, created_time }) => {
          return <ul style={{color: '#666', fontSize: '14px'}}>
            <li style={{marginBottom: '6px'}}>
              <span>姓名：</span>
              <span>{username}</span>
            </li>
            <li style={{marginBottom: '6px'}}>
              <span>账号：</span>
              <span>{account}</span>
            </li>
            <li style={{
                color: '#999',
                fontSize: '13px',
                textAlign: 'right'
              }}>
              {created_time}
            </li>
          </ul>
        }}
    </ListCards>
  </div>
}

export default List
