import React from 'react'
import { Graph, Node } from '@antv/x6'
import { register } from '@antv/x6-react-shape'
import { Snapline } from '@antv/x6-plugin-snapline'
import { getId } from '@/utils'
import bus from '@/utils/bus'
import styles from './index.module.scss'
import store from '@/store'
// import { processesAdd } from '@/apis/process'

function Circle(props: any) {
  return <div className={styles.circle} style={{ backgroundColor: props.bgColor, color: props.fnColor }}>
    <svg className='icon' aria-hidden='true' width="22" height="22" style={{
      userSelect: 'none',
      pointerEvents: 'none'
    }}>
      <use xlinkHref={`#${props.icon}`}></use>
    </svg>
    <div>{props.name || props.label}</div>
  </div>
}

function Rect(props: any) {
  return <div className={styles.rect} style={{ backgroundColor: props.bgColor, color: props.fnColor }}>
    <svg className='icon' aria-hidden='true' width="22" height="22" style={{
      userSelect: 'none',
      pointerEvents: 'none',
      marginRight: '6px'
    }}>
      <use xlinkHref={`#${props.icon}`}></use>
    </svg>
    <div>{props.name || props.label}</div>
  </div>
}

function Rhomb(props: any) {
  return <div className={styles.rhomb} style={{ backgroundColor: props.bgColor, color: props.fnColor }}>
    <svg className='icon' aria-hidden='true' width="22" height="22" style={{
      userSelect: 'none',
      pointerEvents: 'none',
      marginRight: '6px'
    }}>
      <use xlinkHref={`#${props.icon}`}></use>
    </svg>
    <div>{props.name || props.label}</div>
  </div>
}

// function UpTriangle(props: any) {
//   return <div className={styles.upTriangle} style={{ backgroundColor: props.bgColor, color: props.fnColor }}>
//     <div>
//       <img
//         className={styles.icon}
//         src={props.icon}
//         alt={props.name} />
//     </div>
//     <div>{props.name || props.label}</div>
//   </div>
// }

// function DownTriangle(props: any) {
//   return <div className={styles.downTriangle} style={{ backgroundColor: props.bgColor, color: props.fnColor }}>
//     <div>
//       <img
//         className={styles.icon}
//         src={props.icon}
//         alt={props.name} />
//     </div>
//     <div>{props.name || props.label}</div>
//   </div>
// }

const mapping: { [key: string]: React.FC } = {
  start: Circle,
  end: Circle,
  active: Rect,
  condition: Rhomb,
  split: Circle,
  merge: Circle,
  task: Rect,
};

Object.keys(mapping).forEach((key: string) => {
  let item: X6NODE = store.state.x6InitNodes.filter((i: any) => i.id === key)[0]
  const Comp = mapping[key] as React.FC as any
  register({
    shape: item.id,
    width: item.width,
    height: item.height,
    ports: item.ports,
    component: ({ node }: { node: Node }) => {
      const label = node.prop('label')
      return (
        <Comp {...item} label={label} />
      )
    }
  })
})

export default class X6 extends React.Component {
  private container!: HTMLDivElement
  private graph!: Graph
  public state = {
    config: {
      x: 100,
      y: 100,
      type: '',
    }
  }

  setSelectedNode = (attrs: ANY_JSON) => {
    store.setAttrs(attrs)
  }
  // 链接桩的显示与隐藏，主要是照顾菱形
  changePortsShow = (val: Boolean) => {
    const ports = this.container.querySelectorAll('.x6-port-body')
    for (let i = 0, len = ports.length; i < len; i = i + 1) {
      const el = ports[i] as HTMLHtmlElement
      el.style.visibility = val ? 'visible' : 'hidden'
    }
    const labels = this.container.querySelectorAll('.x6-port-label')
    for (let i = 0, len = labels.length; i < len; i = i + 1) {
      const el = labels[i] as HTMLHtmlElement
      el.style.visibility = val ? 'visible' : 'hidden'
    }
  }

  componentDidMount() {
    this.graph = new Graph({
      container: this.container,
      // 是否可编辑。 true 可编辑、 false 预览模式
      interacting: true,

      // 画布 抓手工具
      panning: true,
      // 滚轮缩放工具
      mousewheel: true,
      connecting: {
        // 不允许连接到画布空白位置的点
        allowBlank: false,
        // 不允许创建循环连线，即边的起始节点和终止节点为同一节点
        allowLoop: false,
        // 不允许边连接到节点（非节点上的连接桩）
        allowNode: false,
        // 不允许边连接到另一个边
        allowEdge: false,
        // 不允许边连接到连接桩
        allowPort: true,
        // 允许在相同的起始节点和终止之间创建多条边
        allowMulti: true,
        createEdge() {
          return this.createEdge({
            attrs: {
              line: {
                stroke: '#8f8f8f',
                strokeWidth: 1,
              },
            },
          })
        },
        validateMagnet({ magnet }) {
          // 节点上方的连接桩无法创建连线
          return magnet.getAttribute('port-group') !== 'top'
        },
        validateConnection({
          sourceCell,
          targetCell,
          sourceMagnet,
          targetMagnet,
        }) {
          // 不能连接自身
          if (sourceCell !== targetCell) {
            // 只能从 bottom 连接桩开始连接，连接到 top 连接桩
            if (!((sourceMagnet && sourceMagnet.getAttribute('port-group') === 'bottom') && (targetMagnet && targetMagnet.getAttribute('port-group') === 'top'))) {
              return false
            }

            // 底部连接桩只允许出一条路由
            const sourceProp = sourceCell?.getProp()
            if(['start', 'active', 'merge', 'condition', 'task'].includes(sourceProp?.shape as any )) {
              const edges = this.getEdges()
              if (edges.filter((item: any) => (item.source.cell === sourceProp?.id)).length > 1) {
                return false
              }
            }

            // 顶部连接桩只允许接收一条路由
            const targetProp = targetCell?.getProp()
            if(['end', 'active', 'split', 'condition', 'task'].includes(targetProp?.shape as string as any)) {
              const edges = this.getEdges()
              if (edges.filter((item: any) => (item.target.cell === targetProp?.id)).length > 0) {
                return false
              }
            }
          }

          return true
        },
      },
      grid: {
        visible: true,
        type: 'doubleMesh',
        args: [
          {
            color: '#eee', // 主网格线颜色
            thickness: 1, // 主网格线宽度
          },
          {
            color: '#ddd', // 次网格线颜色
            thickness: 1, // 次网格线宽度
            factor: 4, // 主次网格线间隔
          },
        ],
      },
      background: {
        color: '#F2F7FA',
      }
    })
    this.graph.use(
      new Snapline({ enabled: true })
    )
    
    this.graph.on('cell:mouseenter', ({ cell }) => {
      this.changePortsShow(true)
      if(!['start'].includes(cell.id)) {
        if (cell.isNode()) {
          cell.addTools([
            {
              name: 'boundary',
              args: {
                attrs: {
                  fill: '#7c68fc',
                  stroke: '#333',
                  'stroke-width': 1,
                  'fill-opacity': 0.2,
                },
              },
            },
            {
              name: 'button-remove',
              args: {
                x: 0,
                y: 0,
                offset: { x: 10, y: 10 },
              },
            },
          ])
        } else {
          cell.addTools(['vertices', 'segments'])
        }
      }
    })

    this.graph.on('cell:mouseleave', ({ cell }) => {
      this.changePortsShow(false)
      cell.removeTools()
    })
    

    this.graph.fromJSON({
      nodes: [],
      edges: [],
    })
    this.graph.centerContent()

    this.graph.on('node:dblclick', ({view, e}: any) => {
      e.stopPropagation()
      if(['active', 'condition', 'task'].includes(view.cell.store.data.shape)) {
        this.setSelectedNode(view.cell.store.data)
      }
    })

    this.graph.on('blank:click', ({e}: any) => {
      e.stopPropagation()
      this.setSelectedNode({
        id: '',
        shape: ''
      })
    })

    bus.on('x6.export', this.handleClickExport)
    bus.on('x6.addNodePosition', (param: any) => {
      this.setState({
        config: {
          ...this.state.config,
          x: param.x,
          y: param.y,
        }
      })
    })
    bus.on('x6.addNodeId', (param: any) => {
      this.setState({
        config: {
          ...this.state.config,
          type: param.id,
        }
      }, () => {
        // 只允许创建一个结束节点
        if(['end'].includes(param.id as string)) {
          const { cells } = this.graph.toJSON()
          const endArr = cells.filter(item => ['end'].includes(item?.shape as string))
          if(endArr.length >= 1) {
            return false
          }
        }
        // 只允许创建一个开始节点
        if(['start'].includes(param.id as string)) {
          const { cells } = this.graph.toJSON()
          const endArr = cells.filter(item => ['start'].includes(item?.shape as string))
          if(endArr.length >= 1) {
            return false
          }
        }

        const [ config ] = store.state.x6InitNodes.filter((item: any) => {
          return item.id === this.state.config.type
        })
        const p = this.graph.localToGraph(this.graph.pageToLocal(this.state.config.x, this.state.config.y))
        const {clientWidth, clientHeight} = this.container
        const halfW = clientWidth / 2
        const halfH = clientHeight / 2
        this.graph.addNode({
          id: getId(),
          shape: this.state.config.type,
          x: p.x > halfW ? p.x - halfW : -(halfW - p.x),
          y: p.y > halfH ? p.y - halfH : -(halfH - p.y),
          label: config.name,
          width: config.width,
          height: config.height,
        })
      })
    })
  }

  refContainer = (container: HTMLDivElement) => {
    this.container = container
  }

  handleClickExport = () => {
    console.log(this.graph.toJSON())
    console.log(JSON.stringify(this.graph.toJSON()))
    // processesAdd({
    //   processes_key: '',
    //   processes_name: '',
    //   configure: JSON.stringify(this.graph.toJSON())
    // }).then(() => {
      
    // })
  }

  render() {
    return (
      <div className={styles.reactShapeApp}>
        <div
          onDragEnter={() => {}}
          onDragOver={(e) => {
            e.preventDefault()
          }}
          onDragLeave={() => {}}
          onDrop={(e: any) => {
            bus.emit('x6.addNodePosition', {
              x: e.pageX,
              y: e.pageY,
            } as any)
          }}
          className={styles.appContent}
          ref={this.refContainer} />
      </div>
    )
  }
}